import React, { useState } from "react";

const users = [
  { user: "balaji.n@indigoquotient.com", password: "Balaji123!" },
  { user: "anvith@indigoquotient.com", password: "Anvith123!" },
  { user: "arya.balankp@indigoquotient.com", password: "Arya123!" },
  { user: "karthick@neomotion.co.in", password: "Karthick7597!" },
  { user: "ashish@neomotion.co.in", password: "Ashish6833!" },
  { user: "hari.prasad@indigoquotient.com", password: "Hari123!" },
];

const LoginPage = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleEnterPressed = (event) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  const handleLogin = () => {
    // Perform login logic here
    let isUserFound = false;
    users.forEach((user) => {
      if (user.user === username && user.password === password) {
        onLogin();
        isUserFound = true;
        return;
      }
    });

    if (!isUserFound) alert("Invalid credentials");
  };

  return (
    <div style={styles.container}>
      <img
        src={require("./assets/images/iql-logo-dark.png")}
        width={400}
        alt=""
      />

      <h2>Login Page</h2>

      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        style={styles.input}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={styles.input}
        onKeyDown={handleEnterPressed}
      />
      <button onClick={handleLogin} style={styles.button}>
        Login
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  input: {
    padding: "10px",
    margin: "10px 0",
    width: "200px",
    fontSize: "16px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
  },
};

export default LoginPage;
