import React, { useState, useEffect, useContext } from "react";
import {
  GoogleMap,
  LoadScript,
  MarkerF,
  HeatmapLayer,
} from "@react-google-maps/api";
import { io } from "socket.io-client";
import { StateContext } from "./context/StateProvider";

const LIBRARIES = ["visualization"];
const Maps = ({ props }) => {
  const [heatmapData, setHeatmapData] = useState(null);
  const [position, setPosition] = useState(null);
  const { userDeviceId } = useContext(StateContext);

  // to initialize the heatMap data and the position
  useEffect(() => {
    setHeatmapData(props?.heatmap);
    setPosition(props?.location);
  }, [props]);

  const [transformedData, setTransformedData] = useState(null);
  let socket;

  useEffect(() => {
    socket = io("https://backend.iqlabs.io:3002");
    socket.on("connect", () => {
      //  console.log("Connection Successful");
    });

    const interval = setInterval(() => {
      if (window.google && heatmapData?.length > 0) {
        // console.log(window.google);
        const data = heatmapData?.map((point) => ({
          location: new window.google.maps.LatLng(point.lat, point.lng),
          weight: point.weight,
        }));

        setTransformedData(data);
      }
    }, 5000);

    socket.on("mqtt", (msg) => {
      if (msg == null) return;

      const msgJson = JSON.parse(msg);
      const payload = JSON.parse(msgJson.payload);
      // console.log("Received Message", payload);
      // console.log("Latitude", payload.Latitude);
      // console.log("Longitude", payload.Longitude);
      if (
        payload.Mac_Id === userDeviceId &&
        payload.Latitude &&
        payload.Longitude
      ) {
        setPosition({ lat: payload.Latitude, lng: payload.Longitude });
      }
    });

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
  }, [heatmapData]);

  // useEffect(() => {
  //   const interval = setInterval(() => {}, 5000);
  //   return () => clearInterval(interval);
  // }, [position]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBcgqRhNZTzePlgvGoB3mgYqE_fEkL1yuA"
      libraries={LIBRARIES}
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={position}
        zoom={16}
      >
        {transformedData?.length > 0 && <HeatmapLayer data={transformedData} />}
        <MarkerF position={position} />
      </GoogleMap>
    </LoadScript>
  );

  //   return isLoaded ? (
  //     <GoogleMap
  //       mapContainerStyle={containerStyle}
  //       center={center}
  //       zoom={10}
  //       onLoad={onLoad}
  //       onUnmount={onUnmount}
  //     >
  //       {/* Child components, such as markers, info windows, etc. */}
  //       <></>
  //     </GoogleMap>
  //   ) : (
  //     <></>
  //   );
};

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

export default Maps;
