import React, { useState, useContext, useEffect } from "react";
import Card from "./Card";
import Maps from "./Maps";
import searchVehicle from "./assets/images/searchVehicle.jpg";
import Modal from "./Modal";
import haversine from "haversine-distance";
import { fetchDeviceLogs } from "./api/vehicle";
import { StateContext } from "./context/StateProvider";

const HomePage = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isSearchError, setIsSearchError] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [totalDistance, setTotalDistance] = useState(0);
  const [transition, setTransition] = useState({
    imgTransition: false,
    searchIconTransition: false,
    searchCloseTransition: false,
  });
  const {
    setUserDeviceId,
    cardData,
    userDeviceId,
    setDistanceObj,
    distanceObj,
  } = useContext(StateContext);
  const [filteredCardData, setFilteredCardData] = useState(cardData);
  const [cardHeatMap,setCardHeatMap] = useState(cardData)

  // update the distanceObj
  useEffect(() => {
    setDistanceObj((prevState) => ({
      ...prevState,
      [userDeviceId]: totalDistance,
    }));
  }, [totalDistance, userDeviceId]);

  // useEffect to handle searchError Message display
  useEffect(()=>{
    const timeOut = setTimeout(()=>{
      setIsSearchError(false)
    },3000)
    return ()=>clearTimeout(timeOut)
  })

  // calculate the distance between 2 points using DirectionService Google API
  const CalDistance = (a, b) => {
    if (window.google) {
      let directionsService = new window.google.maps.DirectionsService();
      const route = {
        origin: a,
        destination: b,
        travelMode: "DRIVING",
      };

      directionsService.route(route, function (response, status) {
        // console.log("in service");
        if (status !== "OK") {
          console.log("Directions request failed due to " + status);
          return;
        } else {
          var directionsData = response.routes[0].legs[0];
          if (!directionsData) {
            console.log("Directions request failed");
            return;
          } else {
            // console.log(
            //   "directionsData.distance.value:",
            //   directionsData.distance.value
            // );
            setTotalDistance(
              (prevTotalDistance) =>
                prevTotalDistance + directionsData.steps[0].distance.value
            );
          }
        }
      });
    }
  };

  // loop to calculate the distance between 2 co-ordinates
  const findDistance = (data) => {
    if (data?.length > 1) {
      for (let i = 1; i < data?.length; i++) {
        const item = data[i];
        const prevItem = data[i - 1];
        const b = {
          lat: item.latitude,
          lng: item.longitude,
        };
        const a = {
          lat: prevItem.latitude,
          lng: prevItem.longitude,
        };
        CalDistance(a, b);
      }
    }
  };

  // function to generate the heatMap using haversine distance
  const generateHeatMap = (MapData) => {
    let updatedHeatMapData = [];
    if (MapData.length > 1) {
      MapData.forEach((item, i) => {
        const newLocation = {
          lat: item.Latitude || item.latitude,
          lng: item.Longitude || item.longitude,
          weight: 1,
        };
        if (i > 0) {
          let flag = false;
          const a = {
            latitude: item.Latitude || item.latitude,
            longitude: item.Longitude || item.longitude,
          };
          const b = {
            latitude: MapData[i - 1].Latitude || MapData[i - 1].latitude,
            longitude: MapData[i - 1].Longitude || MapData[i - 1].longitude,
          };
          const distance = Math.floor(haversine(a, b));
          if (distance <= 50) {
            MapData[i - 1].weight += 0.3;
            flag = true;
          }
          if (flag) {
            newLocation.weight += 0.3;
          }
        }
        updatedHeatMapData.push(newLocation);
      });
    } else {
      if (MapData.length === 1) {
        updatedHeatMapData.push({
          lat: MapData[0].Latitude || MapData[0].latitude,
          lng: MapData[0].Longitude || MapData[0].longitude,
          weight: 1,
        });
      }
    }
    return updatedHeatMapData;
  };

  // fetch the device logs to generate the heat map
  const fetchData = async (userId, index) => {
    try {
      const result = await fetchDeviceLogs(userId);
      if (result) {
        if (result.data.length > 0) {
          setTotalDistance(0);
          findDistance(result.data);
          const heatMapGenerated = generateHeatMap(result.data);
          // console.log("heatMapGenerated", heatMapGenerated);
          const revisedData = cardData.map((item) => {
            // userId matches the selected deviceId, then update the heatmap, else push the item
            if (item.userId === result.data[0].deviceId) {
              const len = heatMapGenerated.length - 1;
              return {
                ...item,
                heatmap: [...heatMapGenerated],
                location: {
                  lat: heatMapGenerated[len].lat,
                  lng: heatMapGenerated[len].lng,
                },
              };
            } else {
              return { ...item };
            }
          });
          setCardHeatMap(revisedData);
        }
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error Fetching logs:", error);
    }
  };

  // handle card Click
  const handleClick = async (userId, index) => {
    setSelectedIndex(index);
    setUserDeviceId(userId);
    setTotalDistance(0);
    fetchData(userId, index);

    const interval = setInterval(async () => {
      fetchData(userId, index);
    }, 30000);
    return () => clearInterval(interval);
  };

  // handle all the transitions
  const handleTransition = (keyData, valueData) => {
    setTransition((prevState) => ({
      ...prevState,
      [keyData]: valueData,
    }));
  };

  // handle Search on press enter key / click on search icon
  const handleSearch = () => {
    handleTransition("imgTransition", !transition.imgTransition);
    const filteredData = cardData.filter((item) =>
      item.userId.toLowerCase().includes(searchText.toLowerCase())
    );
    if (filteredData.length > 0 && searchText.length > 0) {
      setFilteredCardData(filteredData);
    } else {
      setIsSearchError(true);
      setFilteredCardData(cardData);
    }
  };
  // styling
  const styles = {
    homeContainer: {
      display: "flex",
      flexDirection: "row",
      height: "100vh",
      position: "relative",
    },
    vehicleContainer: {
      flex: "0.2",
      display: "flex",
      flexDirection: "column",
    },
    cardContainer: {
      marginTop: "185px",
      overflowY: "auto",
      cursor: "pointer",
    },
    header: {
      position: "fixed",
      width: "20%",
      backgroundColor: "white",
      paddingTop: "10px",
      zIndex: "1",
    },
    heading: {
      margin: "5px 16px",
      marginBottom: "7px",
      letterSpacing: "1px",
      textShadow: "0 4px 6px rgba(0,0,0,0.19) 0 8px 16px rgba(0,0,0,0.1) ",
      color: "#858181",
    },
    addContainer: {
      display: "flex",
      margin: "5px 16px",
      marginBottom: "12px",
    },
    addBtn: {
      padding: "4px 8px",
      border: "none",
      cursor: "pointer",
      fontSize: "15px",
    },
    plusIcon: {
      color: "#46b980",
      fontSize: "30px",
      backgroundColor: "buttonface",
      paddingRight: "8px",
    },
    searchContainer: {
      width: "20%",
      margin: "4px 16px",
      marginBottom: "15px",
      display: "flex",
      alignItems: "center",
      height: "40px",
      position: "relative",
    },
    searchImgContainer: {
      border: "2px solid #46b980",
      padding: "6px 4px",
      paddingRight: "4px",
      borderRadius: "4px 0 0 4px",
      display: "flex",
    },
    searchImg: {
      transition: "transform 0.7s ease-in-out",
      borderRadius: "16px",
      transform: transition.imgTransition ? "rotate(360deg)" : "",
    },
    searchIcon: {
      fontSize: "24px",
      color: "#46b980",
      backgroundColor: transition.searchIconTransition
        ? "transparent"
        : "#F0F0F0",
      border: "1px solid #46b980",
      borderRadius: "4px",
      padding: "5px",
      cursor: "pointer",
      marginLeft: "10px",
    },
    close: {
      position: "absolute",
      right: "-150px",
      cursor: "pointer",
      color: transition.searchCloseTransition ? "#e5e5e5" : "#A5A2A2",
      display: searchText.length > 0 ? "block" : "none",
    },
    searchText: {
      height: "40px",
      width: "150px",
      border: "2px solid #46b980",
      borderLeft: "none",
      fontSize: "15px",
      paddingLeft: "4px",
      paddingRight: "20px",
      letterSpacing: "0.6px",
      borderRadius: "0 4px 4px 0",
    },
    errorSearch:{
      textAlign:"center",
      margin:"2px",
      color:"#BD2627",
      display: isSearchError ? "block":"none",
    },
    mapContainer: {
      flex: "0.8",
      height: "100%",
    },
    overlay: {
      position: "fixed",
      display: isOpen ? "block" : "none",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: " 2",
    },
    ModalContainer: {
      position: "absolute",
      backgroundColor: "white",
      height: "40%",
      width: "35%",
      zIndex: "100",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)",
      borderRadius: "10px",
      padding: "5px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.16), 0 8px 16px 0 rgba(0,0,0,0.19)",
    },
  };

  return (
    <div style={styles.homeContainer}>
      <div style={styles.overlay}>
        {/* Modal */}
        {isOpen && (
          <div style={styles.ModalContainer}>
            <Modal close={() => setIsOpen(!isOpen)} />
          </div>
        )}
      </div>
      {/* Menu(header/cards) container (20%) */}
      <div style={styles.vehicleContainer}>
        <div style={styles.header}>
          <h1 style={styles.heading}>
            <span style={{ color: "#46b980" }}>B</span>olts
            <span style={{ color: "#46b980" }}>.</span>
          </h1>
          <div style={styles.addContainer} onClick={() => setIsOpen(!isOpen)}>
            <button style={styles.addBtn}>Add Vehicle</button>
            <span style={styles.plusIcon}>+</span>
          </div>
          {/* Search for the vechicle Id */}
          <div style={styles.searchContainer}>
            <div style={styles.searchImgContainer}>
              <img
                src={searchVehicle}
                alt="vehicle"
                height="30px"
                width="30px"
                style={styles.searchImg}
              />
            </div>
            <input
              type="text"
              placeholder="Vehicle Id"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyDown={(e)=>{
                if(e.key === 'Enter'){
                  handleSearch()
                }
              }}
              style={styles.searchText}
            />
            <span
              style={styles.close}
              onClick={() => {
                setSearchText("");
                setFilteredCardData(cardData);
              }}
              onMouseOver={() =>
                handleTransition("searchCloseTransition", true)
              }
              onMouseLeave={() =>
                handleTransition("searchCloseTransition", false)
              }
            >
              X
            </span>
            <button
              style={styles.searchIcon}
              onMouseOver={() => handleTransition("searchIconTransition", true)}
              onMouseLeave={() =>
                handleTransition("searchIconTransition", false)
              }
              onClick={handleSearch}
            >
              <i className="bi bi-search"></i>
            </button>
          </div>
          <div style={styles.errorSearch}>Vehicle Id not found</div>
        </div>
        {/* Display cards */}
        <div style={styles.cardContainer}>
          {filteredCardData.map((item, index) => (
            <div
              key={item.userId}
              onClick={() => handleClick(item.userId, index)}
            >
              <Card
                props={item}
                distanceTravelled={distanceObj[item.userId]}
              />
            </div>
          ))}
        </div>
      </div>
      {/* Map container (80% )*/}
      <div style={styles.mapContainer}>
        <Maps
          props={cardHeatMap[selectedIndex]}
        />
      </div>
    </div>
  );
};

export default HomePage;
