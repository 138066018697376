// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Los Andes  Lota Grotesque Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

@font-face {
  font-family: "Lota Grotesque Regular";
  src: local(" Lota Grotesque Regular"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: normal;
}
* {
  font-family: "Lota Grotesque Regular";
}
*:focus {
  outline: none;
}
h3{
  margin:0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,qCAAqC;EACrC,iGAA8G;EAC9G,mBAAmB;AACrB;AACA;EACE,qCAAqC;AACvC;AACA;EACE,aAAa;AACf;AACA;EACE,QAAQ;AACV","sourcesContent":["body {\r\n  margin: 0;\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Lota Grotesque Regular\";\r\n  src: local(\" Lota Grotesque Regular\"), url(\"./fonts/Los Andes  Lota Grotesque Regular.otf\") format(\"truetype\");\r\n  font-weight: normal;\r\n}\r\n* {\r\n  font-family: \"Lota Grotesque Regular\";\r\n}\r\n*:focus {\r\n  outline: none;\r\n}\r\nh3{\r\n  margin:0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
