import React, { useState } from "react";
import { addVehicleData } from "./api/vehicle";
const Modal = ({ close }) => {
  const [formValue, setFormValue] = useState({ deviceId: "", vehicleId: "" });
  const [error, setError] = useState([]);
  const [resMessage, setResMessage] = useState("");
  const [response, setResponse] = useState(false);
  const [btnTransition, setBtnTransition] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleResponse = () => {
    setResponse(true);
    const timeOut = setTimeout(() => {
      setResponse(false);
    }, 3000);

    return () => clearTimeout(timeOut);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    handleErrors();
    if (!error.device_error && !error.vehicle_error) {
      try {
        const result = await addVehicleData(formValue); 
           
        if (result) {
          setResMessage("Successfully added Vehicle Id");
          handleResponse();
          setFormValue({ deviceId: "", vehicleId: "" });
        } else {
          setResMessage("Failed to add Vehicle Id");
          handleResponse();
        }
      } catch (error) {
        console.error("Error adding vehicle:", error);
        setResMessage("Failed to add Vehicle Id");
        handleResponse();
      }
    }
   
  };


  const handleErrors = () => {
    let errors = {};
    if (formValue.deviceId.length === 0) {
      errors.device_error = "Device Id is required";
    } else {
      errors.device_error = "";
    }
    if (formValue.vehicleId.length === 0) {
      errors.vehicle_error = "vehicle Id is required";
    } else {
      errors.vehicle_error = "";
    }

    setError(errors);
  };

  const styles = {
    HeadingContainer: {
      padding: "20px 40px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    ModalHeading: {
      fontSize: "20px",
      textAlign: "center",
      color: "#46b980",
    },
    ModalClose: {
      cursor: "pointer",
      textAlign: "right",
      fontSize: "20px",
      padding: "10px",
      margin: "0",
    },
    form: {
      marginTop: "20px",
      textAlign: "center",
    },
    formData: {
      marginTop: "5px",
      marginBottom: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    formLabel: {
      width: "25%",
    },
    formInput: {
      padding: "5px",
      border: "0",
      width: "60%",
      position: "relative",
    },
    vehicleError: {
      borderBottom: error.vehicle_error ? "2px solid red" : "2px solid #46b980",
    },
    deviceError: {
      borderBottom: error.device_error ? "2px solid red" : "2px solid #46b980",
    },
    addVehicleContainer: {
      width: "100%",
    },
    addVehicleBtn: {
      marginTop: "20px",
      padding: "8px 22px",
      cursor: "pointer",
      fontSize: "16px",
      color: "#46b980",
      border: "2px solid #46b980",
      borderRadius: "4px",
      backgroundColor: btnTransition && "transparent",
    },
    error: {
      color: "red",
      position: "absolute",
      right: "40px",
    },
    resMessage: {
      textAlign: "center",
      margin: "0",
      display: response ? "block" : "none",
    },
  };
  return (
    <>
      <p style={styles.ModalClose}>
        <i className="bi bi-x-lg" onClick={close}></i>
      </p>
      <h3 style={styles.ModalHeading}>Add Vehicle</h3>
      <h4 style={styles.resMessage}>{response && resMessage}</h4>
      <form style={styles.form} onSubmit={handleFormSubmit}>
        <div style={styles.formData}>
          <label htmlFor="deviceId" style={styles.formLabel}>
            Device Id :
          </label>
          <input
            type="text"
            id="deviceId"
            name="deviceId"
            value={formValue.deviceId}
            onKeyUp={handleErrors}
            onChange={handleInputChange}
            placeholder="Enter Device Id"
            style={{ ...styles.formInput, ...styles.deviceError }}
          />
          <span style={styles.error}>
            {error.device_error && <i className="bi bi-exclamation-circle"></i>}
          </span>
        </div>
        <div style={styles.formData}>
          <label htmlFor="vehicleId" style={styles.formLabel}>
            vehicle Id :
          </label>
          <input
            type="text"
            id="vehicleId"
            name="vehicleId"
            value={formValue.vehicleId}
            onKeyUp={handleErrors}
            onChange={handleInputChange}
            placeholder="Enter vehicle Id"
            style={{ ...styles.formInput, ...styles.vehicleError }}
          />
          <span style={styles.error}>
            {error.vehicle_error && (
              <i className="bi bi-exclamation-circle"></i>
            )}
          </span>
        </div>
        <div style={styles.addVehicleContainer}>
          <button
            type="submit"
            style={styles.addVehicleBtn}
            onMouseOver={() => setBtnTransition(!btnTransition)}
            onMouseLeave={() => setBtnTransition(!btnTransition)}
          >
            Add
          </button>
        </div>
      </form>
    </>
  );
};

export default Modal;
