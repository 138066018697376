import React, { useContext, useState } from "react";
import car from "./assets/images/neoBolt.jpg";
import { StateContext } from "./context/StateProvider";
const Card = ({ props,distanceTravelled }) => {
 
  const [transition, setTransition] = useState({
    cardHover: false,
    editHover: false,
    deleteHover: false,
  });
  const {userDeviceId} = useContext(StateContext)

  // handle card  transition
     const handleTransition = (keyData, valueData) => {
      setTransition((prevState) => ({
        ...prevState,
        [keyData]: valueData,
      }));
    };
  // Styling Card
  const styles = {
    card: {
      width: "85%",
      margin: "20px auto",
      padding: "15px 5px",
      paddingLeft: "10px",
      borderRadius: "10px",
      lineHeight: "25px",
      display: "flex",
      gap: "8px",
      transition: "transform 0.3s ease-in",
      transform: transition.cardHover ? "scale(0.97)" : "scale(1)",
      backgroundColor: userDeviceId === props.userId ? "#e5e5e5" : "",
      boxShadow:
      userDeviceId === props.userId
          ? "0 4px 8px 0 rgba(70, 185, 128, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          : "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    },
    imgs: {
      width: "30%",
      height: "75px",
      borderRadius: "5px",
    },
    textContainer: {
      width: "65%",
      height: "75px",
      paddingBottom: "7px",
    },
    cardId: {
      margin: "5px 0",
      color: "#2D2A2A",
      fontSize: "15px",
      letterSpacing: "1px",
      marginBottom: "0",
      paddingLeft: "3px",
    },
    cardNameContainer: {
      fontSize: "10px",
      padding: "0",
      lineHeight: "1.4",
      paddingLeft: "3px",
      letterSpacing: "0.6px",
    },
    cardName: {
      margin: "0",
      color: "#9A9595",
    },
    cardNumber: {
      margin: "0",
      color: "#46b980",
      fontWeight: "bold",
    },
    distanceContainer: {
      display: "flex",
      gap: "6px",
      marginTop: "4px",
    },
    distance: {
      margin: "0",
      borderBottom: "1px solid black",
    },
    active: {
      margin: "0",
    },
    headerContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    icons: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "3px",
    },
    editStyle: {
      color: transition.editHover ? "#726ED4" : "#6B686D",
    },
    deleteStyle: {
      color: transition.deleteHover ? "#BD2627" : "#6B686D",
    },
  };
  return (
    <div
      style={styles.card}
      onMouseOver={() => handleTransition("cardHover", true)}
      onMouseLeave={() => handleTransition("cardHover", false)}
    >
      <img src={car} alt="car" style={styles.imgs} />

      <div style={styles.textContainer}>
        <div style={styles.headerContainer}>
          <h3 style={styles.cardId}>{props.userId}</h3>
          <div style={styles.icons}>
            <span
              style={styles.editStyle}
              onMouseOver={() => {
                handleTransition("editHover", true);
              }}
              onMouseLeave={() => {
                handleTransition("editHover", false);
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </span>
            <span
              style={styles.deleteStyle}
              onMouseOver={() => {
                handleTransition("deleteHover", true);
              }}
              onMouseLeave={() => {
                handleTransition("deleteHover", false);
              }}
            >
              <i className="bi bi-trash3"></i>
            </span>
          </div>
        </div>

        <div style={styles.cardNameContainer}>
          <p style={styles.cardName}>Neo Bolt • 2021 Model</p>
          <p style={styles.cardNumber}>1FTEW!ERTU0987UR</p>
          <div style={styles.distanceContainer}>
            <p style={styles.distance}>
             {
                (distanceTravelled < 1000
                  ? `${distanceTravelled}m`
                  : `${distanceTravelled/ 1000}km`)}
            </p>
            <p style={styles.active}>
              <span style={{ color: "#60c977" }}> ● </span>
              Active
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
