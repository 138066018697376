import { createContext, useState } from "react";

export const StateContext = createContext();
const StateProvider = ({ children }) => {
  // data
  const data = [
    {
      userId: "B8D61AB294FC",
    },
    {
      userId: "E014B729E748",
    },
  ];

  const [cardData, setCardData] = useState(data);
  const [userDeviceId, setUserDeviceId] = useState("");
  // initialize the distance object with the userId to 0
  const initialDistanceObj = (data) => {
    return data.reduce((acc, curr) => {
      const { userId } = curr;
      acc[userId] = 0;
      return acc;
    }, {});
  };
  const [distanceObj, setDistanceObj] = useState(initialDistanceObj(data));

  return (
    <StateContext.Provider
      value={{
        cardData,
        setCardData,
        userDeviceId,
        setUserDeviceId,
        distanceObj,
        setDistanceObj,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export default StateProvider;
