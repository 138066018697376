import { addVehicle } from "./constant";
import { fetchLogs } from "./constant";
// to add vehicle Id and Device Id
export const addVehicleData = async (formValue) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...formValue, token: "8lQzN922rpRDaMbh3m5J" }),
    };

    const res = await fetch(addVehicle, requestOptions);
    const data = await res.json();

    if (!res.ok) {
      throw new Error(data.message || "Failed to add vehicle");
    }
    return data;
  } catch (error) {
    console.error("Error adding vehicle:", error);
    throw error;
  }
};

// to fetch the device logs
export const fetchDeviceLogs = async (deviceId) => {
  try {
    const token = '8lQzN922rpRDaMbh3m5J'
    const res = await fetch(`${fetchLogs}?token=${token}&deviceId=${deviceId}`);
    const data = await res.json();
    // console.log("DATA :", data);
    if (!res.ok) {
      throw new Error(data.message || "Error getting Logs");
    }
    return data;
  } catch (error) {
    console.error("Error getting Logs", error);
    throw error;
  }
};
